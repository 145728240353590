import { writable } from 'svelte/store'
import { browser } from '$app/env'

export const isAuthenticated = writable<Maybe<boolean>>(null)

export const userProfile = writable<Maybe<UserProfile>>(null)

export const authenticatedUserId = writable<Maybe<string>>(null)

export const idToken = writable<Maybe<string>>((browser && localStorage.getItem('id_token')) || null)
export const accessToken = writable<Maybe<string>>((browser && localStorage.getItem('id_token')) || null)

export const isAuthMenuOpened = writable<boolean>(false)
